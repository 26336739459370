import UIkit from 'uikit'
import { showAlert } from './util'
let actionTopic

getNumberOfTexts()

getTopics()

function getNumberOfTexts() {
  const url = '/texts/not'
  const params = {
    method: 'GET'
  }
  
  fetch(url, params).then((response) => {
    return response.json()
  }).then((res) => {
    console.log(res.numberOfTexts)
    const svg = document.getElementById('progress-card')
    const number = svg.childNodes[3].childNodes[0].childNodes[0].childNodes[0]
    const bar = svg.childNodes[3].childNodes[27].childNodes[0]
    let index = 0;

    const interval = 1200 / res.numberOfTexts

    let progressInterval= setInterval(() => {
      number.innerHTML = index
      bar.setAttribute('width', index)
      index++
      if (index > res.numberOfTexts) {
        clearInterval(progressInterval)
      }
    }, interval)
  })
}

function getTopics() {
  let topicsAccordion = document.getElementById('topics-accordion')
  topicsAccordion.innerHTML = ''
  const url = '/topics'
  const params = {
    method: 'GET'
  }
  
  fetch(url, params).then((response) => {
    return response.json()
  }).then((topics) => {
    console.log(topics)
    topics.forEach(topic => {
      topicsAccordion.appendChild(createAccordionListItem(topic))
    });
  })
}

function createAccordionListItem(topic) {
  // ListItem
  const listItem = document.createElement('li')
  const label = document.createElement('span')
  if (topic.available) {
    label.className = 'uk-label uk-label-success'
    label.innerText = 'Verfügbar'
  } else {
    label.className = 'uk-label uk-label-warning'
    label.innerText = 'Reserviert'
  }
  // Title
  const itemTitle = document.createElement('a')
  itemTitle.className = 'uk-accordion-title'
  itemTitle.href = '#'
  itemTitle.innerText = topic.name
  // Content
  const itemContentDiv = document.createElement('div')
  itemContentDiv.className = 'uk-accordion-content'
  // // Description
  const descriptionP = document.createElement('p')
  descriptionP.innerText = (topic.description) ? topic.description : 'Für dieses Thema gibt es keine Beschreibung'
  // // Button
  const topicFunctionButton = createButton(topic)
  // // Divider
  const divider = document.createElement('hr')
  // // Combine
  itemContentDiv.appendChild(descriptionP)
  itemContentDiv.appendChild(topicFunctionButton)
  itemContentDiv.appendChild(document.createElement('hr'))
  
  // Combine
  listItem.appendChild(itemTitle)
  listItem.appendChild(label)
  listItem.appendChild(itemContentDiv)
  listItem.appendChild(divider)
  
  return listItem
}

function createButton(topic) {
  const button = document.createElement('button')
  button.className = 'uk-button uk-button-primary uk-button-small'
  if (topic.available) {
    button.innerText = 'Reservieren'
    button.setAttribute('uk-toggle', 'target: #reserve-modal')
    button.onclick = () => {
      const reserveTopicTitle = document.getElementById('reserve-topic-title')
      reserveTopicTitle.innerText = topic.name
      actionTopic = topic
    }
  } else {
    button.innerText = 'Beitrag abgeben'
    button.setAttribute('uk-toggle', 'target: #submit-text-modal')
    button.onclick = () => {
      const submitTextTitle = document.getElementById('submit-text-title')
      submitTextTitle.innerText = topic.name
      actionTopic = topic
    }
  }
  button.type = 'button'
  
  return button
}

document.getElementById('reserve-submit-button').addEventListener('click', (event) => {
  const reserveModal = document.getElementById('reserve-modal')
  const reservedBy = document.getElementById('reserve-topic-reserved-by').value
  const reservedByEmail = document.getElementById('reserve-topic-reserved-by-email').value
  event.preventDefault()
  if (reservedBy) {
    const url = `/topics/${actionTopic._id}?_method=patch`
    const data = {
      available: false,
      reservedBy: reservedBy,
      reservedByEmail: reservedByEmail
    }
    const params = {
      headers: {
        'content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(data),
      method: 'POST'
    }
    fetch(url, params).then((response) => {
      return response.json()
    }).then((data) => {
      console.log(data)
      UIkit.modal(reserveModal).hide()
      getTopics()
      if (data._id) {
        console.log('läuft')
        showAlert('success', `Das Thema »${data.name}« wurde für dich reserviert`)
      } else {
        
      }
    })
  } else {
  }
})

document.getElementById('submit-text-submit-button').addEventListener('click', (event) => {
  const submitTextModal = document.getElementById('submit-text-modal')
  const subtitle = document.getElementById('submit-text-subtitle').value
  const verse = document.getElementById('submit-text-verse').value
  const bibleText = document.getElementById('submit-text-bible-text').value
  const text = document.getElementById('submit-text-text').value
  const author = document.getElementById('submit-text-author').value
  const authorEmail = document.getElementById('submit-text-author-email').value
  event.preventDefault()
  if (author && verse && text) {
    const url = `/texts`
    const data = {
      topic: actionTopic.name,
      subtitle: subtitle,
      verse: verse,
      bibleText: bibleText,
      text: text,
      author: author,
      email: authorEmail
    }
    const params = {
      headers: {
        'content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(data),
      method: 'POST'
    }
    fetch(url, params).then((response) => {
      return response.json()
    }).then((data) => {
      console.log(data)
      UIkit.modal(submitTextModal).hide()
      getTopics()
      if (data._id) {
        showAlert('success', `Dein Beitrag »${data.subtitle}« zum Thema »${data.topic}« wurde gespeichert`)
        getNumberOfTexts()
        document.getElementById('text-submit-form').reset()
      } else {
        
      }
    })
  } else {
    
  }
})

document.getElementById('proposal-submit-button').addEventListener('click', (event) => {
  const proposalModal = document.getElementById('proposal-modal')
  const name = document.getElementById('proposal-name').value
  const description = document.getElementById('proposal-description').value
  const available = !document.getElementById('proposal-available').checked
  const creator = document.getElementById('proposal-creator').value
  const creatorEmail = document.getElementById('proposal-creator-email').value
  event.preventDefault()
  if (name && description && creator) {
    const url = `/topics`
    const data = {
      name: name,
      description: description,
      available: available,
      creator: creator,
      creatorEmail: creatorEmail,
      reservedBy: (available) ? null : creator,
      reservedByEmail: (available) ? null : creatorEmail
    }
    const params = {
      headers: {
        'content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(data),
      method: 'POST'
    }
    fetch(url, params).then((response) => {
      return response.json()
    }).then((data) => {
      console.log(data)
      UIkit.modal(proposalModal).hide()
      getTopics()
      if (data._id) {
        showAlert('success', `Der Themenvorschlag »${data.name}« wurde hinzugefügt`)
      } else {
        
      }
    })
  } else {
    
  }
})
