export function showAlert(type, message) {
  const alertDiv = document.getElementById('alert-div')
  const alert = document.getElementById('alert')
  const alertMessage = document.getElementById('alert-message')
  alertDiv.hidden = false
  alertMessage.innerText = message
  window.scroll(0, 0)
  switch (type) {
    case 'primary':
      alert.className = 'uk-alert-primary uk-alert'
      break;
    case 'success':
      alert.className = 'uk-alert-success uk-alert'
      break;
    case 'warning':
      alert.className = 'uk-alert-warning uk-alert'
      break;
    case 'danger':
      alert.className = 'uk-alert-danger uk-alert'
      break;

    default:
      alertDiv.hidden = true
      break;
  }
}

export function getAuthenticatedUser() {
  if (document.cookie) {
    const cookies = document.cookie.split(';')
    const authenticated = cookies.find(cookie => /^authenticated=/.test(cookie))
    return authenticated.split('=')[1]
  } else {
    return null
  }
}